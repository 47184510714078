// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: `${ROOTS.DASHBOARD}/dashboard`,
    games: `${ROOTS.DASHBOARD}/games`,
    players: `${ROOTS.DASHBOARD}/players`,
    playertypes: `${ROOTS.DASHBOARD}/playertypes`,
    branches: `${ROOTS.DASHBOARD}/branches`,
    categories: `${ROOTS.DASHBOARD}/categories`,
    programs: `${ROOTS.DASHBOARD}/programs`,
   
    members: {
      root: `${ROOTS.DASHBOARD}/members`,
    },

    contents: {
      root: `${ROOTS.DASHBOARD}/contents`,
      news: `${ROOTS.DASHBOARD}/contents/news`,
    },

    siteManagement: {
      root: `${ROOTS.DASHBOARD}/siteManagement`,
      slider: `${ROOTS.DASHBOARD}/siteManagement/slider`,
      social: `${ROOTS.DASHBOARD}/siteManagement/social-account`,
    },

    // group: {
    //   root: `${ROOTS.DASHBOARD}/group`,
    //   five: `${ROOTS.DASHBOARD}/group/five`,
    //   six: `${ROOTS.DASHBOARD}/group/six`,
    // },
  },
};
