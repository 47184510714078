import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home'));
const GamesPage = lazy(() => import('src/pages/dashboard/games'));
const PlayersPage = lazy(() => import('src/pages/dashboard/players'));
const BranchesPage = lazy(() => import('src/pages/dashboard/branches'));
const CategoriesPage = lazy(() => import('src/pages/dashboard/categories'));
const NewsPage = lazy(() => import('src/pages/dashboard/news'));
const MembersPage = lazy(() => import('src/pages/dashboard/members'));
const ContentsPage = lazy(() => import('src/pages/dashboard/contents'));
const SiteMenuPage = lazy(() => import('src/pages/dashboard/site-menu'));
const SocialAccountsPage = lazy(() => import('src/pages/dashboard/social-account'));
const SliderPage = lazy(() => import('src/pages/dashboard/slider'));
const ProgramsPage = lazy(() => import('src/pages/dashboard/programs'));
const PlayerTypes = lazy(() => import('src/pages/dashboard/playertypes'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'games', element: <GamesPage /> },
      { path: 'players', element: < PlayersPage /> },
      { path: 'playertypes', element: < PlayerTypes /> },
      { path: 'branches', element: <BranchesPage /> },
      { path: 'categories', element: <CategoriesPage /> },
      { path: 'programs', element: <ProgramsPage /> },
     

       {
        path: 'members',
        children: [
          { element: <MembersPage />, index: true },
          // { path: 'five', element: <PageFive /> },
        ],
      },

      {
        path: 'contents',
        children: [
          { element: <ContentsPage />, index: true },
          { path: 'news', element: <NewsPage /> },
          // { path: 'five', element: <PageFive /> },
        ],
      },

      {
        path: 'siteManagement',
        children: [
          { element: <SiteMenuPage />, index: true },
          { path: 'slider', element: <SliderPage /> },
          { path: 'social-account', element: <SocialAccountsPage /> },
          // { path: 'five', element: <PageFive /> },
        ],
      },
      
      // {
      //   path: 'group',
      //   children: [
      //     { element: <PageFour />, index: true },
      //     { path: 'five', element: <PageFive /> },
      //     { path: 'six', element: <PageSix /> },
      //   ],
      // },
    ],
  },
];
